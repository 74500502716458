import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Alert } from 'react-bootstrap';
import moment from 'moment';
import { Requerimiento } from '../../../model/RequerimientoMateriales/Requerimiento';

import * as ComponentFunctions from './RequerimientoMaterialesList.Functions';

export const RequerimientoMaterialesListComponent = (props: ComponentFunctions.Props) => {
    const history = useHistory();
    const [SearchText, setSearchText] = useState<string>('');
    const [showAlert, setShowAlert] = useState(false);
    const [radioSelection, setRadioSelection] = useState<string>('REQUERIMIENTO_NUMBER');

    useEffect(() => {
        props.GetAll('0', '5000', '', '');
    }, []);

    //Buscar solo si posee texto almacenado en 'SearchText'
    useEffect(() => {
        if (SearchText !== '' || SearchText?.length > 0) {
            searchHandler();
        }

    }, [radioSelection]);

    //Carga nuevamente la lista de Requerimiento de materiales al borrarse o limpiarse el campo 'SearchText'.
    useEffect(() => {
        if (SearchText == '' || SearchText?.length === 0) {
            searchHandler();
        }
    }, [SearchText]);

    // events
    const pressEnterHandler = (e: any) => {
        return e.key === 'Enter' ? searchHandler() : e;
    };

    const searchHandler = () => {
        props.GetAll('0', '5000', SearchText, radioSelection);
    };

    const removeFilterHandler = () => {
        setSearchText('');
    };

    const deleteRequerimientoHandler = (requerimiento: Requerimiento) => {
        window.confirm(`¿Está seguro que desea eliminar el requerimiento de materiales: '${requerimiento.codigo} versión ${requerimiento.version}' ?`) && props.Delete(requerimiento.id);
    };

    // Funcion para manejar los cambios en la selección de los radio buttons
    const handleRadioSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRadioSelection(e.target.value);
    };

    const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };


    const SearchBar = (
        <div className="searchBar">
            <div className="input-group">
                <input
                    type="text"
                    placeholder="Ingrese el texto a buscar..."
                    className="form-control"
                    onKeyDown={pressEnterHandler}
                    onChange={handleSearchText}
                    value={SearchText}
                />
                {SearchText !== '' && (
                    <button
                        className="input-group-text"
                        id="basic-addon2"
                        onClick={removeFilterHandler}
                    >
                        X
                    </button>
                )}
            </div>

        </div>

    );
    // Radio button para manejar el filtrado correctamente.
    const RadioButtons = (
        <div className="">
            <div className="form-check form-check-inline mt-2">
                <input
                    type="radio"
                    id="requerimiento_number"
                    value="REQUERIMIENTO_NUMBER"
                    checked={radioSelection === 'REQUERIMIENTO_NUMBER'}
                    onChange={handleRadioSelection}
                    className="form-check-input"
                />
                <label htmlFor="requerimiento_number" className="form-check-label">Nro. de Requerimiento</label>
            </div>

            <div className="form-check form-check-inline">
                <input
                    type="radio"
                    id="provider"
                    value="PROVIDER"
                    checked={radioSelection === 'PROVIDER'}
                    onChange={handleRadioSelection}
                    className="form-check-input"
                />
                <label htmlFor="provider" className="form-check-label">Proveedor</label>
            </div>
        </div>
    );



    const GettingSuccessfully = (
        <>
            <div>
                <h5>Requerimiento de materiales registrados</h5>
                <div className="seccion">
                    {SearchBar}
                    {RadioButtons}
                    <hr></hr>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Nro. de Requerimiento</th>
                                <th>Version</th>
                                <th>Proveedor</th>
                                <th>Fecha</th>
                                <th>Precio Total (s/IVA)</th>
                                <th>Precio Total (c/IVA)</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.requerimientos?.filter((requerimiento: any) => {
                                if (SearchText !== '') {
                                    if (radioSelection === 'PROVIDER') {
                                        return requerimiento.codigoproveedor.toLowerCase().includes(SearchText.toLowerCase());
                                    } else {
                                        //Requerimiento
                                        return (
                                            requerimiento.id.toString().toLowerCase().includes(SearchText.toLowerCase())
                                        );
                                    }
                                } else {
                                    return true;
                                }
                            })
                                .sort((a: Requerimiento, b: Requerimiento) =>
                                    moment(b.creado).valueOf() - moment(a.creado).valueOf()
                                ) // Ordenar por fecha de manera descendente
                                ?.map((requerimiento: any, index: number) => (
                                    <tr key={index}>
                                        <td>
                                            <strong className="upper">{requerimiento.codigo}</strong>
                                        </td>
                                        <td>
                                            <strong className="upper">{requerimiento.version}</strong>
                                        </td>
                                        <td>
                                            {requerimiento.codigoproveedor}
                                        </td>
                                        <td>
                                            <label className="upper">{moment(requerimiento.creado).format('DD/MM/YYYY').toLocaleString()}</label>
                                        </td>
                                        <td>
                                            {`${requerimiento.totalSinIva} u$d`}
                                        </td>
                                        <td>
                                            {`${requerimiento.totalConIva} u$d`}
                                        </td>
                                        <td>
                                            <div className="controls">
                                                <button
                                                    className="btn btn-outline-secondary btn-sm"
                                                    onClick={() => history.push(`requerimientos-materiales/${requerimiento.id}`)}
                                                >
                                                    Editar
                                                </button>
                                                <button
                                                    className="btn btn-outline-danger btn-sm"
                                                    onClick={() => deleteRequerimientoHandler(requerimiento)}
                                                >
                                                    Borrar
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    {props.requerimientos?.length === 0 && (
                        <label>No hay requerimientos de materiales registrados</label>
                    )}
                </div>
            </div>
        </>
    );

    const GettingFail = props.failOnLoading && props.error && (
        <div className="ErrorContainer">
            <label>{props.error?.ErrorMessage}</label>
            <ul>
                {props.error?.Errors.map((error: string) => (
                    <li>{error}</li>
                ))}
            </ul>
        </div>
    );

    return (
        <>
            <div className="contentpage">
                {(props.isFilteres && SearchText.length > 0) && (
                    <Alert variant="warning">
                        Filtro aplicado: <strong>{props.filterCriteria}</strong>
                    </Alert>
                )}
                {props.failOnDelete && showAlert && (
                    <Alert variant="danger" className="alert-dismissible stycky-top">
                        <label>Atención</label>
                        {props.error && (
                            <ul>
                                {props.error?.Errors?.map((error: string) => (
                                    <li>{error}</li>
                                ))}
                            </ul>
                        )}
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Cerrar"
                            onClick={() => setShowAlert(false)}
                        ></button>
                    </Alert>
                )}
                {props.LoadingSuccessfully && props.requerimientos && GettingSuccessfully}
                {props.failOnLoading && GettingFail}
            </div>
        </>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(RequerimientoMaterialesListComponent as any);