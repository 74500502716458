import * as React from 'react';
import './Layout.css';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../stores';
import { SideBarComponent } from './SideBar/SideBarComponent';
import { HeaderComponent } from './Header/HeaderComponent';

export const Layout = (props: { children?: React.ReactNode }) => {
  const AuthenticationState = useSelector(
    (state: ApplicationState) => state.authentication
  );

  return (
    <div className="layout">
      {AuthenticationState?.isAuthenticate && <SideBarComponent />}
      <div className="layoutContent">
        <HeaderComponent />
        <div className="layoutChildContent">{props.children}</div>
      </div>
    </div>
  );
};

export default Layout;
