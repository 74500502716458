import { useEffect, useState } from 'react';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import MateriaPrimaHeader from '../components/materia_prima/Header/MateriaPrimaHeader';
import { ChangeStockAmountForm } from '../components/stock/Forms/changeStockAmountform';
import { WidgetStock } from '../components/stock/widget/widgetStock';
import { StockMovementListComponent } from '../components/stock/widget/widgetStockMovementList';
import { ApplicationState } from '../stores';
import ProductCostComponent from '../components/producto/ProductoCosto/ProductCostComponent';
import ReplacementCostComponent from '../components/producto/ReplacementCost/ReplacementCostComponent';
import AverageCostComponent from '../components/producto/AverageCost/AverageCostComponent';
import * as ProductosStore from '../stores/Productos';

// stores
import * as MateriaPrimaStore from '../stores/Materia_Prima';

export const MateriaPrimaPage = () => {
  const [ShowForm, setShowForm] = useState<boolean>(false);
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const SuppliesState = useSelector(
    (state: ApplicationState) => state.Supplies
  );

  const OnSaveNewStockAmountHandler = (
    materiaPrimaId: number,
    newAmount: number,
    Comments: string
  ) => {
    if (materiaPrimaId === SuppliesState?.SelectedSupply?.id) {
      dispatch(
        mapDispatchToProps.SetSupplyNewStockAmount(materiaPrimaId, newAmount, Comments)
      );
    }
    setShowForm(false);
  };

  useEffect(() => {
    dispatch(mapDispatchToProps.GetAllSupplies(0, 5000));
  }, []);

  useEffect(() => {
    if (SuppliesState && SuppliesState.Supplies.length > 0)
      dispatch(mapDispatchToProps.SetSelectedSupplyByID(Number(id)));
  }, [SuppliesState?.Supplies]);

  return SuppliesState?.Supplies ? (
    <>
      <div className="pageHeader">
        {SuppliesState?.SelectedSupply && (
          <MateriaPrimaHeader
            supply={SuppliesState?.SelectedSupply!}
          ></MateriaPrimaHeader>
        )}
        <button
          type="button"
          title="Volver"
          className="btn btn-primary mt-3 mb-2 me-3 float-end"
          onClick={() => {
            history.goBack();
          }}
        >
          <FaArrowAltCircleLeft />
        </button>
      </div>
      <div className="contentpage">
      <div className="productInfoDetailsContainer">
        <div>
          <AverageCostComponent productId={Number(id)} />
        </div>
        <div>
          <ReplacementCostComponent productId={Number(id)} />
        </div>
        <div>
          <ProductCostComponent productId={Number(id)} />
        </div>
      </div>
        <div className="orderDetailsContainer">
          <div>
            <h5>Movimientos de Stock</h5>
            <StockMovementListComponent
                StockId={SuppliesState?.SelectedSupply?.stock.id}
                refreshMovements={ShowForm}
              />
            {/* {SuppliesState?.SelectedSupply?.stock.lista_movimientos && (
              
            )} */}
          </div>
          <div>
            <div className="seccion">
              {SuppliesState?.SelectedSupply?.stockId && (
                <WidgetStock
                  StockId={SuppliesState?.SelectedSupply?.stockId}
                  CurrentStock={SuppliesState?.SelectedSupply?.stock.cantidad}
                  MinimumStockAllowed={
                    SuppliesState.SelectedSupply.minimoStockAceptable
                  }
                  StockMeasurmentUnit={
                    SuppliesState.SelectedSupply.unidadDeMedida
                  }
                  onSolicitateChangeStockAmount={() => setShowForm(true)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <>
        {ShowForm && SuppliesState.SelectedSupply?.stockId && (
          <div className="modalForm">
            <h5>Nueva cantidad de stock</h5>
            <ChangeStockAmountForm
              CurrentStockAmount={SuppliesState.SelectedSupply?.stock.cantidad!}
              EntityId={SuppliesState.SelectedSupply?.id!}
              onCancel={() => setShowForm(false)}
              onSaveNewStockTransaction={OnSaveNewStockAmountHandler}
              StockId={SuppliesState.SelectedSupply?.stockId!}
            />
          </div>
        )}
      </>
    </>
  ) : null;
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.Supplies,
  ...state.productos,
  ...state.formulas
});

const mapDispatchToProps = {
  ...MateriaPrimaStore.actionCreators,
  ...ProductosStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MateriaPrimaPage as any);
