import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Select, { SingleValue } from 'react-select';
import { Form } from 'react-bootstrap';
import { FaArrowAltCircleLeft, FaMinus, FaPlus } from 'react-icons/fa';
import * as MateriaPrimaStore from '../../stores/Materia_Prima';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../stores';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { IMainStoreModel, ServiceStatus } from '../../stores/easy-pease/store';
import { Requerimiento } from '../../model/RequerimientoMateriales/Requerimiento';
import { Proveedor } from '../../model/Proveedor';
import { useProveedores } from '../../services/Hooks/useProveedores';
import { MateriaPrima } from '../../model/materia_prima';
import { useRequerimientosMateriales } from '../../services/Hooks/useRequerimientosMateriales';

export const NuevoRequerimientoMaterialesPage = () => {
  const history = useHistory();
  const defaultDetalle = {
    materiaPrima: undefined,
    cantidad: undefined,
    descripcion: undefined,
    fechaEntrega: undefined,
    montoConIva: undefined,
    montoSinIva: undefined,
    precio: undefined,
  };
  const AuthState = useSelector(
    (state: ApplicationState) => state.authentication
  );
  const item:Requerimiento = useStoreState<IMainStoreModel>((state) => state.requerimientoMateriales);
  const setItem = useStoreActions<IMainStoreModel>(
    (actions) => actions.setRequerimientoMateriales
  );
  const proveedores = useStoreState<IMainStoreModel>((state) => state.proveedores);
  const estadoServicioProveedores = useStoreState<IMainStoreModel>(
    (state) => state.estadoServicioProveedores
  );

  const { resetServiceStatus: resetProveedoresServiceStatus, getAllProveedores } = useProveedores();
  const { resetServiceStatus: resetRequerimientosMaterialesServiceStatus, createRequerimientoMateriales } = useRequerimientosMateriales();

  const loading = !estadoServicioProveedores || (estadoServicioProveedores.status === ServiceStatus.processing);
  
  const SuppliesState = useSelector(
      (state: ApplicationState) => state.Supplies
    );
  const mapDispatchToProps = {
    ...MateriaPrimaStore.actionCreators
  };

  const dispatch = useDispatch();  

  const handleSaveClick = () => {
    if (AuthState && AuthState.authenticatedUser) { 
      createRequerimientoMateriales(item, AuthState!.authenticatedUser!.jwToken, callbackSuccess);
    }
  }

  React.useEffect(() => {
    resetProveedoresServiceStatus();
    resetRequerimientosMaterialesServiceStatus();
    setItem({
      id: 0,
      codigo: 0,
      version: 0,
      proveedor: {
        id: 0,
        telefono: "",
        mail: "",
        contacto: "",
      },
      plazoPago: 10,
      detalles: [defaultDetalle],
    });
    loadData();
  }, [])

  const callbackSuccess = (id: number) => {
    history.push(`/requerimientos-materiales/${id}`);
  }

  const loadData = async () => {
    dispatch(mapDispatchToProps.GetAllSupplies(0, 5000));
    getAllProveedores();
  }

  const handleProviderChange = (p: any) => {
    setItem({ ...item, ...{ proveedor: p, detalles: [defaultDetalle] } });
  }

  const handleFieldChange = (field: string, e: React.ChangeEvent<HTMLInputElement>, ) => {
    setItem({ ...item, ...{ proveedor: { ...item.proveedor, [field]: e.target.value } } });
  }

  const handleMateriaPrimaChange = (selected: SingleValue<MateriaPrima>, index: number) => {
    if (!SuppliesState || !SuppliesState.Supplies) {
      return;
    }
    const selectedMateria = selected as typeof SuppliesState.Supplies[0];
    const newDetalles = [...item.detalles];
    newDetalles[index] = {
      ...newDetalles[index],
      cantidad: 0,
      precio: 0,
      montoConIva: 0,
      montoSinIva: 0,
      fechaEntrega: new Date(),
      materiaPrima: {
        ...newDetalles[index].materiaPrima,
        id: selectedMateria.id,
        codigo: selectedMateria.codigo,
        descripcion: selectedMateria.descripcion,
      },
    };

    setItem( { ...item, ...{ detalles: newDetalles } } );
  }

  const handleAddDeleteClick = (index?: number) => {
    if (!index) {
      const newDetalles = [...item.detalles, defaultDetalle];
      setItem({ ...item, ...{ detalles: newDetalles } });
      return;
    }
    
    const newDetalles = item.detalles.filter((_, i) => i !== index);
    setItem({ ...item, ...{ detalles: newDetalles } });
  }

  return (<>
      <div className="pageHeader">
        <h2>Nuevo requerimiento de materiales</h2>
        <button
          type="button"
          title="Volver"
          className="btn btn-primary mt-3 mb-2 me-3 float-end"
          onClick={() => {
            setItem(undefined);
            resetProveedoresServiceStatus();
            resetRequerimientosMaterialesServiceStatus();
            history.push('/requerimientos-materiales');
          }}
        >
          <FaArrowAltCircleLeft />
        </button>
      </div>
      <div className='contentpage'>
        <form>
          <div className="col-md-12 d-flex align-items-center mb-2">
            <div className="col-md-3 mb-2">
              <Form.Group controlId="formBasicSelect">
                <Form.Label>Proveedor</Form.Label>
                <Select
                  options={proveedores}
                  getOptionLabel={(option) => option.codigo + ' - ' + option.nombre}
                  getOptionValue={(option) => option.codigo}
                  isDisabled={loading && !proveedores}
                  placeholder="Seleccione un proveedor"
                  value={proveedores?.filter(
                    (option: Proveedor) => option.codigo === item?.proveedor?.codigo
                  )}
                  onChange={handleProviderChange}
                />
              </Form.Group>
            </div>
            <div className="col-md-3 mb-2 ps-2">
              <Form.Label>Telefono</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                value={item?.proveedor.telefono}
                disabled={!item?.proveedor}
                onChange={(e:any) => { handleFieldChange("telefono", e) }}
              />
            </div>
            <div className="col-md-3 mb-2 ps-2">
              <Form.Label>Mail</Form.Label>
              <Form.Control
                type="email"
                className="form-control"
                value={item?.proveedor.mail}
                disabled={!item?.proveedor}
                onChange={(e:any) => { handleFieldChange("mail", e) }}
              />
            </div>
            <div className="col-md-3 mb-2 ps-2">
              <Form.Label>Contacto</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                value={item?.proveedor.contacto}
                disabled={!item?.proveedor}
                onChange={(e:any) => { handleFieldChange("contacto", e) }}
              />
            </div>
          </div>
          <div className="col-md-12 d-flex align-items-center">
          <div className="col-md-3 mb-2 d-flex  align-items-center">
              <Form.Label className='w-100'>Plazo de pago</Form.Label>
              <Form.Control
                type="number"
                className="form-control"
                value={item?.plazoDias}
                min={0}
                onChange={(e: any) => {
                  setItem({ plazoDias: e.target.value });
                }}
              />
              <span className='ms-3'> días</span>
            </div>
          </div>
        </form>
        <hr />
        {!loading && item?.proveedor && (<table className="table table-hover">
          <thead>
            <tr>
              <th>Artículo</th>
              <th>Descripción</th>
              <th>Cantidad</th>
              <th>Fecha entrega</th>
              <th>Precio</th>
              <th>Subtotal</th>
              <th>Total (c/IVA)</th>
            </tr>
          </thead>
          <tbody>
          {item && item.detalles.map((detalle, index) => (
            <tr key={index}>
              {/* Materia prima (React Select) */}
              <td>
                <Select
                  options={SuppliesState!.Supplies!.filter(mp => mp.codigoproveedor === item?.proveedor.codigo)}
                  getOptionLabel={(option) => `${option.codigo} - ${option.nombre}`}
                  getOptionValue={(option) => String(option.id)}
                  placeholder="Seleccione un material"
                  value={SuppliesState?.Supplies?.find((mp) => mp.id === detalle.materiaPrima?.id)}
                  onChange={(selected: SingleValue<MateriaPrima>) => { handleMateriaPrimaChange(selected, index) }}
                />
              </td>

              {/* Descripción (Solo lectura) */}
              <td>
                <Form.Control
                  type="text"
                  className="form-control"
                  value={detalle.descripcion || ""}
                  readOnly
                  tabIndex={-1}
                />
              </td>

              {/* Cantidad */}
              <td>
                <Form.Control
                  type="number"
                  min={0}
                  className="form-control"
                  disabled={!detalle.materiaPrima?.id}
                  value={detalle.cantidad || ""}
                  onChange={(e) => {
                    const cantidad = parseFloat(e.target.value) || 0;
                    const newDetalles = [...item.detalles];
                    
                    const precio = newDetalles[index].precio || 0;

                    newDetalles[index] = { ...newDetalles[index], ...{
                        cantidad,
                        montoSinIva: cantidad * precio,
                        montoConIva: cantidad * precio * 1.21,
                      }
                    };

                    setItem( { ...item, ...{ detalles: newDetalles } } );
                  }}
                />
              </td>

              {/* Fecha de entrega */}
              <td>
                <input
                  className="form-control"
                  type="date"
                  disabled={!detalle.materiaPrima?.id}
                  value={
                    detalle.fechaEntrega
                      ? moment(detalle.fechaEntrega).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const fechaEntrega = new Date(e.target.value);
                    const newDetalles = [...item.detalles];
                    
                    newDetalles[index] = { ...newDetalles[index], ...{
                        fechaEntrega,
                      }
                    };
                    
                    setItem( { ...item, ...{ detalles: newDetalles } } );
                  }}
                />
              </td>

              {/* Precio */}
              <td>
                <Form.Control
                  type="number"
                  min={0}
                  className="form-control"
                  disabled={!detalle.materiaPrima?.id}
                  value={detalle.precio || ""}
                  onChange={(e) => {
                    const precio = parseFloat(e.target.value) || 0;                    
                    const newDetalles = [...item.detalles];
                    
                    const cantidad = newDetalles[index].cantidad || 0;

                    newDetalles[index] = { ...newDetalles[index], ...{
                        precio: precio,
                        montoSinIva: cantidad * precio,
                        montoConIva: cantidad * precio * 1.21,
                      }
                    };

                    setItem( { ...item, ...{ detalles: newDetalles } } );
                  }}
                />
              </td>

              {/* Subtotal (Solo lectura) */}
              <td>
                <Form.Control
                  type="text"
                  className="form-control"
                  value={detalle.precio && detalle.cantidad 
                    ? (detalle.cantidad * detalle.precio).toFixed(2)
                    : "0.00"}
                  readOnly
                  tabIndex={-1}
                />
              </td>

              {/* Total con IVA (Solo lectura) */}
              <td>
                <div className='d-flex'>
                <Form.Control
                  type="text"
                  className="form-control"
                  value={detalle.precio && detalle.cantidad 
                    ? ((detalle.cantidad * detalle.precio)*1.21).toFixed(2)
                    : "0.00"}
                  readOnly
                  tabIndex={-1}
                />
                {index > 0 && index !== item.detalles.length -1 && (<button
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => { handleAddDeleteClick(index) }}
                  title="Agregar"
                >
                  <FaMinus />
                </button>)}
                {index === item.detalles.length -1 && (<button
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => { handleAddDeleteClick() }}
                  title="Agregar"
                >
                  <FaPlus />
                </button>)}
                </div>
                
              </td>
            </tr>
          ))}

            <tr>
              <td colSpan={5} align="right">
                <strong>Total General</strong>
              </td>
              <td>
                <strong>
                  {item.detalles
                    .reduce((sum, detalle) => sum + ((detalle.cantidad * detalle.precio) || 0), 0)
                    .toFixed(2)}
                </strong>
              </td>
              <td>
                <strong>
                  {item.detalles
                    .reduce((sum, detalle) => sum + (((detalle.cantidad * detalle.precio)*1.21) || 0), 0)
                    .toFixed(2)}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>)}
        <div>
          <button
            type="button"
            className="btn btn-primary"
            disabled={!item?.proveedor || !item?.plazoDias || item?.detalles.some(x => !x.materiaPrima?.id || !x.cantidad || !x.precio || !x.fechaEntrega)}
            onClick={handleSaveClick}
          >
            Crear requerimiento de materiales
          </button>
        </div>
      </div>
    </>)
};